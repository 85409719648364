import React, { PropsWithChildren, createContext, useContext } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { useEffect, useState } from "react";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { useAuthenticatedAuth } from "./AuthProvider";
import { LoadingScreen } from "../components/LoadingScreen";
import { createRelayEnvironment } from "../helpers/createRelayEnvironment";
import { useTranslation } from "react-i18next";

const fetchKeyOptions = [
  "announcements",
  "configs",
  "devices",
  "playlists",
  "themes",
  "permission_groups",
] as const;
type FetchKey = (typeof fetchKeyOptions)[number];
type FetchKeys = { key: FetchKey; value: number }[];
const FetchKeyContext = createContext<{
  fetchKeys: FetchKeys;
  incrementFetchKey: (key: FetchKey) => void;
}>(null!);

export const RelayProvider = ({
  orgId,
  profileId,
  children,
}: PropsWithChildren & {
  orgId: string;
  profileId: string;
}) => {
  const { t } = useTranslation("App");
  const { secureToken, handleLogout, region } = useAuthenticatedAuth();
  const [environment, setEnvironment] = useState<RelayModernEnvironment>();
  const [fetchKeys, setFetchKeys] = useState<FetchKeys>(
    fetchKeyOptions.map((key) => ({ key, value: 0 })),
  );

  useEffect(() => {
    setEnvironment(
      secureToken == null
        ? undefined
        : createRelayEnvironment({
            secureToken,
            orgId,
            profileId,
            region,
            onNoAuth: () => {
              handleLogout();
              setEnvironment(undefined);
            },
          }),
    );
  }, [secureToken, orgId, profileId, handleLogout, region]);

  if (environment == null && secureToken != null) {
    return <LoadingScreen message={t("Initalizing")} />;
  }

  if (environment == null) {
    return children;
  }

  return (
    <RelayEnvironmentProvider environment={environment}>
      <FetchKeyContext.Provider
        value={{
          fetchKeys,
          incrementFetchKey: (key: FetchKey) => {
            setFetchKeys((keys) =>
              keys.map((k) =>
                k.key === key ? { ...k, value: k.value + 1 } : k,
              ),
            );
          },
        }}
      >
        {children}
      </FetchKeyContext.Provider>
    </RelayEnvironmentProvider>
  );
};

export const useFetchKey = (key: FetchKey) => {
  const fetchKey = useContext(FetchKeyContext).fetchKeys.find(
    (k) => k.key === key,
  );

  if (fetchKey == null) {
    throw new Error(`useFetchKey: key not found ${key}`);
  }

  return fetchKey.value;
};

export const useIncrementFetchKey = () =>
  useContext(FetchKeyContext).incrementFetchKey;
