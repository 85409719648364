import React, {
  PropsWithChildren,
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { defer } from "lodash";
import { AccessDeniedScreen } from "../screens/AccessDeniedScreen";
import {
  RelayEnvironmentProvider,
  graphql,
  useLazyLoadQuery,
} from "react-relay";
import { LoadingScreen } from "../components/LoadingScreen";
import { useOktaAuth } from "@okta/okta-react";
import { AuthProviderQuery } from "./__generated__/AuthProviderQuery.graphql";
import { useTranslation } from "react-i18next";
import { createRelayEnvironment } from "../helpers/createRelayEnvironment";
import { analytics } from "../helpers/analytics";
import { ErrorScreen } from "../screens/ErrorScreen";
import { Region, validRegions } from "../helpers/types";
import { baseUrlForRegion } from "../helpers/baseUrlForRegion";
import { LocalStorage } from "../lib/LocalStorage";

type LoginWithTokenArgs = {
  secureToken: string;
  orgId: string;
  region: Region;
  afterLoginPath?: string;
};

export type UserProfile = {
  profileId: string;
  profileName: string;
  orgId: string;
  orgName: string;
  firstName: string;
  lastName: string;
  email: string;
};

type AuthContextType = (
  | {
      status: "unauthenticated";
    }
  | {
      status: "authenticated";
      region: Region;
      secureToken: string;
      allUserProfiles: UserProfile[];
      activeUserProfile: UserProfile;
      setActiveUserProfile: (profile: UserProfile, token: string) => void;
      handleLogout: () => void;
    }
) & {
  loginWithToken: (args: LoginWithTokenArgs) => void;
};

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = (props: PropsWithChildren) => {
  const { t } = useTranslation("AuthProvider");
  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();
  const [allUserProfiles, setAllUserProfiles] = useState<UserProfile[]>();

  const secureToken = LocalStorage.get("secureToken");
  const currentOrgId = LocalStorage.get("currentOrgId")?.toString();
  const currentRegion = LocalStorage.get("currentRegion");

  const [state, setState] = useState<
    "initalizing" | "ready" | "access_denied" | "error"
  >(secureToken == null ? "initalizing" : "ready");

  const loginWithToken = useCallback(
    ({ secureToken, orgId, region, afterLoginPath }: LoginWithTokenArgs) => {
      if (!validRegions.includes(region)) {
        throw new Error(`Invalid region provided: ${region}`);
      }

      LocalStorage.set("secureToken", secureToken);
      LocalStorage.set("currentOrgId", orgId.toString());
      LocalStorage.set("currentRegion", region);

      defer(() => {
        // we are using the DD auth now, so we can sign out of okta
        // window will redirect automatically to "/login" path
        if (oktaAuthState?.isAuthenticated) {
          oktaAuth.signOut();
        } else if (afterLoginPath != null) {
          window.location.replace(afterLoginPath);
        } else {
          window.location.reload();
        }
      });
    },
    [oktaAuth, oktaAuthState?.isAuthenticated],
  );

  const handleLogout = () => {
    LocalStorage.clear();
    analytics.reset();

    if (oktaAuthState?.isAuthenticated) {
      oktaAuth.signOut();
    } else {
      defer(() => window.location.reload());
    }
  };

  // if the user is authed with okta
  const oktaToken = oktaAuthState?.accessToken?.accessToken;
  const notAuthenticated = oktaToken == null || !oktaAuthState?.isAuthenticated;
  const region = (
    oktaAuthState?.accessToken?.claims.region as string
  )?.toLowerCase() as Region | undefined;

  useEffect(() => {
    if (notAuthenticated) return;

    if (region == null) {
      throw new Error("Region not found on okta access token");
    }

    // exchange the okta token for a DD token
    fetch(`${baseUrlForRegion(region)}/auth`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        okta_token: oktaToken,
      }),
    }).then(async (resp) => {
      if (!resp.ok) {
        setState(resp.status === 401 ? "access_denied" : "error");
        throw new Error("Okta > DD token exchange failed");
      }
      const body = await resp.text();
      const { secureToken, orgId } = JSON.parse(body);
      if (secureToken == null || orgId == null) {
        setState("error");
        throw new Error("Token or org id missing from auth response");
      }
      loginWithToken({ secureToken, orgId, region });
    });
  }, [oktaToken, loginWithToken, region, notAuthenticated]);

  if (state === "access_denied") {
    return <AccessDeniedScreen handleLogout={handleLogout} />;
  }

  if (state === "error") {
    return <ErrorScreen />;
  }

  if (oktaAuthState?.isAuthenticated) {
    return <LoadingScreen message={t("Logging you in")} />;
  }

  if (secureToken == null || currentRegion == null) {
    return (
      <AuthContext.Provider
        value={{
          status: "unauthenticated",
          loginWithToken,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    );
  }

  if (allUserProfiles == null) {
    return (
      <RelayEnvironmentProvider
        environment={createRelayEnvironment({
          secureToken,
          orgId: "",
          profileId: "",
          region: currentRegion,
          onNoAuth: handleLogout,
        })}
      >
        <Suspense
          fallback={<LoadingScreen message={t("Fetching active users")} />}
        >
          <UserProfilesFetcher setAllUserProfiles={setAllUserProfiles} />
        </Suspense>
      </RelayEnvironmentProvider>
    );
  }

  const activeUserProfile =
    currentOrgId == null
      ? undefined
      : allUserProfiles.find((u) => u.orgId === currentOrgId);

  if (activeUserProfile == null) {
    return <AccessDeniedScreen handleLogout={handleLogout} />;
  }

  return (
    <AuthContext.Provider
      value={{
        status: "authenticated",
        region: currentRegion,
        allUserProfiles,
        activeUserProfile,
        secureToken,
        setActiveUserProfile: (profile, token) => {
          LocalStorage.set("currentOrgId", profile.orgId.toString());
          LocalStorage.set("secureToken", token);
          defer(() => window.location.reload());
        },
        handleLogout,
        loginWithToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const UserProfilesFetcher = ({
  setAllUserProfiles,
}: {
  setAllUserProfiles: (users: UserProfile[]) => void;
}) => {
  const { t } = useTranslation("AuthProvider");
  const data = useLazyLoadQuery<AuthProviderQuery>(
    graphql`
      query AuthProviderQuery {
        activeUsers {
          nodes {
            id
            hubId
            fullName
            firstName
            email
            lastName
            organisations {
              nodes {
                id
                hubId
                name
              }
            }
          }
        }
      }
    `,
    {},
  );

  useEffect(() => {
    const profiles = data.activeUsers.nodes.filter(
      (n) => n.organisations.nodes.length > 0,
    );

    setAllUserProfiles(
      profiles.map((n) => ({
        profileId: n.hubId,
        profileName: n.fullName,
        firstName: n.firstName,
        lastName: n.lastName,
        email: n.email,
        orgId: n.organisations.nodes[0].hubId,
        orgName: n.organisations.nodes[0].name,
      })),
    );
  }, [data, setAllUserProfiles]);

  return <LoadingScreen message={t("Setting organisations")} />;
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error("Used useAuth before ready");
  }
  return context;
}

export function useAuthenticatedAuth() {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error("Used useAuthenticatedAuth before ready");
  }
  if (context.status !== "authenticated") {
    throw new Error("useAuthenticatedAuth: User not authenticated");
  }
  return context;
}
