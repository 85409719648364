import React from "react";
import classNames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { makeStyles } from "@mui/styles";
import { colors, fontSize, inter } from "../helpers/theme";

const useClasses = makeStyles({
  baseStyles: {
    fontFamily: inter,
  },
  appText: {
    fontSize: fontSize.body,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  boldText: {
    fontWeight: 600,
    lineHeight: 1.5,
  },
  helperText: {
    color: colors.gray6,
    fontSize: fontSize.detail,
    lineHeight: 1.7,
  },
  label: {
    fontSize: fontSize.small,
    fontWeight: 400,
  },
  heading: {
    fontSize: fontSize.h3,
    fontWeight: 600,
  },
  subHeading: {
    fontSize: fontSize.large,
    fontWeight: 500,
  },
  title: {
    fontSize: fontSize.h2,
    fontWeight: 600,
  },
  heroText: {
    fontSize: fontSize.h1,
    fontWeight: 700,
  },
});

type TextProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

const Span = ({
  klassName,
  className,
  children,
  ...props
}: {
  klassName: keyof ReturnType<typeof useClasses>;
} & TextProps) => {
  const classes = useClasses();
  let content = children;

  if (typeof content === "string") {
    content = content.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    (content as string).match(/https?:\/\/[^\s]+/)?.forEach((link) => {
      content = (content as string).replace(
        link,
        `<a target="_blank" href="${link}">${link}</a>`,
      );
    });
  }

  return (
    // eslint-disable-next-line react/no-danger-with-children
    <span
      className={classNames([
        classes.baseStyles,
        classes[klassName],
        className,
      ])}
      {...props}
      dangerouslySetInnerHTML={
        typeof children === "string"
          ? { __html: content?.toString() ?? "" }
          : undefined
      }
    >
      {typeof children === "string" ? undefined : children}
    </span>
  );
};

export const AppText = (props: TextProps) => (
  <Span klassName="appText" {...props} />
);

export const BoldText = (props: TextProps) => (
  <Span klassName="boldText" {...props} />
);

export const HelperText = (props: TextProps) => (
  <Span klassName="helperText" {...props} />
);

export const Label = (props: TextProps) => (
  <Span klassName="label" {...props} />
);

export const Heading = (props: TextProps) => (
  <Span klassName="heading" {...props} />
);

export const SubHeading = (props: TextProps) => (
  <Span klassName="subHeading" {...props} />
);

export const Title = (props: TextProps) => (
  <Span klassName="title" {...props} />
);

export const HeroText = (props: TextProps) => (
  <Span klassName="heroText" {...props} />
);
